<template>
      <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        right
        backdrop
        shadow
      >
        <sidebar-content :user="user" :exp="exp" :avatarIMG="avatarIMG"/>
        <template #footer>
       <div class="align-items-center px-3 py-5 mb-3">
        <b-button block class="btngrad text-light p-1 " @click="logout">
          <feather-icon
          class="align-text-bottom"
          icon="LogOutIcon"
        />
        <span
          class="ml-1"
          v-text="'Logout'"
        /></b-button>
       </div>
      </template>
      </b-sidebar>
  </template>
  
<script>
import { BSidebar,BButton} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SidebarContent from './SidebarContent.vue'
  export default {
      props:["exp","avatarIMG","user"],
    components: {
      BSidebar,
      SidebarContent,
      BButton
    },
    methods:{
      logout: function() {
      setTimeout(() => {
        this.$store.dispatch("user/removeUser");
        this.$router.go({name:"login"});

        this.$root.auth = null;
      }, 10);
    },
    }
  }
</script>
<style>
.btngrad {
  padding: 0.5rem 1rem;
  position: relative;
  background-color: #0E1116 !important ;
  background-clip: padding-box !important ;
  border: 1px solid transparent !important ;
  border-radius: 1.75rem;
}

.btngrad::before {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: -1;
  background-image: linear-gradient(87.2deg, rgba(164, 136, 255, 0.8) 2.33%, rgba(74, 44, 174, 0.83) 51.02%, #27088E 94.9%) !important;
  border-radius: 1.75rem;
}
.b-sidebar.b-sidebar-right {
    border-radius: 16px 16px 0px 0px;
}
</style>
<template>
    <div>
       <h4 class="mt-1 text-center font-weight-bolder">
        Report a Bug
        </h4>
      <div class="mt-1">
       <b-form-textarea
       v-model="bug"
      placeholder="Describe the bug"
      rows="3"
    />
      </div>
       <b-button
        class="backgroundBtn mt-2 mb-1"
                pill
                style="padding: 16px"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      block
      :disabled="!bug"
      @click="sendBug"
      id="mbtn">
      Send Message
      </b-button>
  </div>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import NotificationService from "@/services/NotificationService.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    BFormCheckbox,BFormTextarea
  },
  directives: {
    Ripple,
  },
  data(){
    return {
        bug:null,
      }
  },
  methods:{
    sendBug:async function(){
        this.$root.$emit("modalbug",false)
          const b=(await NotificationService.sendBug(JSON.parse(localStorage.getItem("auth")).discordId,this.bug)).data
           this.$toast({
        component: ToastificationContent,
        props: {
          title:this.$t("curlang")=='en'?'Message has been sent':'El mensaje se ha enviado ',
          icon: 'CheckIcon',
          text: this.$t("curlang")=='en'?'Thank you very much, your support is of great value':'Muchas gracias, su apoyo es de gran valor',
          variant:'success',
        },
      },
      {
        timeout:4000, 
      })
      this.bug=null
    
    },
  },
}
</script>
<style lang="scss">
.backgroundBtn {
  background: linear-gradient(
    87.2deg,
    rgba(164, 136, 255, 0.8) 2.33%,
    rgba(74, 44, 174, 0.83) 51.02%,
    #27088e 94.9%
  );
  border: transparent;
  padding: 18px 80px;
}
</style>

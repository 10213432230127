
const user = JSON.parse(localStorage.getItem("auth"));
let arra=[]
if(user!=null){

if(user.rol==="SaleI")
{
  arra=[
    {
      title: 'Home',
      route: 'home',
      icon: 'GridIcon',
    },
    {
      title: 'Create Quest',
      route: 'createquest',
      icon: 'StarIcon',
      id:'idtodo'
    },
    {
      title: 'Create Items',
      route: 'createitemquest',
      icon: 'CheckSquareIcon',
      id:'idcalendar'
    },
    {
      title: 'Validate Images',
      route: 'validateimages',
      icon: 'CheckSquareIcon',
      id:'idcalendar'
    }
  ]
  
}}

export default arra
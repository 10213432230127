<template>
</template>
<script>
import AuthService from '@/services/AuthService.js'
export default {
    name:"AutoLogout",
    data:function() {
        return {
            events:["click","mousemove","mousedown","scroll","keypress","load"],
            
          //  events:["mousemove"],
          //  warningTimer:null,
            logoutTimer:null,
            isActive:false,
            isWarning:false,
            entered:0,
            logout:0
        }
        
    },
    mounted:  function()
    {
        this.logout=0;
        this.entered=0;
      /*  this.events.forEach(element => {
            window.addEventListener(element,this.resetTimer);
            
        },this);*/
         this.setTimers();


    },
    updated:  function(){
 /*this.events.forEach(element => {
            window.removeEventListener(element,this.resetTimer);
            
        },this);*/
         this.resetTimer();
    },
    methods:{
        setTimers:  function()
        {
            this.isActive=false;
            this.isWarning=false;
            const dxd=this; 
            
            try {
                 AuthService.getOnline().then(response=>{
                  
                     if(response!=null)
            {
                dxd.isActive=true;
                //         dxd.warningTimer=setTimeout(dxd.warningMessage,25*60*1000);
                
            
            }


                 });
            
            
            } catch (error) {
             this.isActive=false   
            }
            dxd.logoutTimer=setTimeout(dxd.logoutUser,1*60*1000);

   
            
           
            
            
        },
        resetTimer:function()
        {
          //  clearTimeout(this.warningTimer);
           
            this.setTimers();
          //  this.isWarning=false;
           

        },
        userActive:function()
        {
            return this.$root.auth!=null;
        },
        logoutUser:function()
        {
         if(!this.isActive && this.logout==0)
         {
             this.logout=1;
             this.$swal({
        title: 'Logout!',
        text: 'Hi you are about to be logout',
        icon: 'warning',
        showConfirmButton: false,
        timer: 8000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
                setTimeout(() => {
        this.$store.dispatch("user/removeUser");
        this.$router.go({name:"login"});

        this.$root.auth = null;
        clearTimeout(this.logoutTimer);
        this.logoutTimer=null;
      }, 10*1000);

         }
        }


    }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>

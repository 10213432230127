import axios from "axios";
const url = "/api/v1/hob/";
class HOBService{
  
  static getGoalRocks(id) {
    let formData = {
      user:id
    }
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(url+"glrxks",formData,
        { headers: { "Content-Type": "application/json"}});
        const data = res.data;
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  }
  static teamsSales(id) {
    let formData = {
      user:id
    }
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(url+"lsusal",formData,
        { headers: { "Content-Type": "application/json"}});
        const data = res.data;
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  }
  static revObj(id) {
    let formData = {
      user:id
    }
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(url+"arroj",formData,
        { headers: { "Content-Type": "application/json"}});
        const data = res.data;
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  }
  static summaryAUMBSPAll(id,f,aqrt) {
    let formData = {
      ckn:id,
      fn:f,
      aqrt:aqrt
    }
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(url+"mxlrgs",formData,
        { headers: { "Content-Type": "application/json"}});
        const data = res.data;
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  }

  static salesAUMBSPAll(user) 
  {
    let formData = {
        ckn:user
    }
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(url+"cowsatsp",formData,
        { headers: { "Content-Type": "application/json"}});
        const data = res.data;
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  }

  static salesDealsPipelineAll(user,days) 
  {
    let formData = {
        user:user,
        day:days
    }
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(url+"xpdr",formData,
        { headers: { "Content-Type": "application/json"}});
        const data = res.data;
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  }

  static salesStatsAll(user,days) 
  {
    let formData = {
        ckn:user,
        fn:days
    }
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(url+"wstatsp",formData,
        { headers: { "Content-Type": "application/json"}});
        const data = res.data;
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  }

    static badDeb(id,days) {
        let formData = {
          user:id,
          day:days
           }
          axios.defaults.headers.post["Content-Type"] = "application/json";
          return  axios.post(url+"bftqs", formData,
            { headers: { "Content-Type": "application/json" } }
          );
      }

      static excel(formData,opcion) {
        var urld=[url+"bftqs",url+"bftqr",url+"lst/dmrws",url+"grtqs"];
        var urlx=urld[opcion];
       
          return  axios.put(urlx, formData,
            {
              headers:
              {
                  'Content-Type': "application/json",
    
                  'Content-Disposition': "attachment; filename=template.xlsx"
              },
              responseType: 'arraybuffer',
          }
          );
      }

    static customerBetween(id,am) {
        let formData = {
          user:id,
          rango:am
           }
          axios.defaults.headers.post["Content-Type"] = "application/json";
          return  axios.post(url+"bftqr", formData,
            { headers: { "Content-Type": "application/json" } }
          );
      }

      static LostCustomers(id) {
        let formData = {
          user:id
           }
          axios.defaults.headers.post["Content-Type"] = "application/json";
          return  axios.post(url+"cxstt", formData,
            { headers: { "Content-Type": "application/json" } }
          );
      }

      static insertAnnual(user,anb,amnr,objrev,objcustomer,objarpu,objmrc,pipetrg,piperev) {
        let formData = {
          user:user,
          anb:anb,
          amnr:amnr,
          objrev:objrev,
          objcustomer:objcustomer,
          objarpu:objarpu,
          objmrc:objmrc,
          pipetrg:pipetrg,
          piperev:piperev 
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
    
        return  axios.post(url + "cravt", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static insertObjReps(sel,anb,amnr,objrev,objcustomer,objarpu,objmrc,pipetrg,piperev) {
        let formData = {
          sel:sel,
          anb:anb,
          amnr:amnr,
          objrev:objrev,
          objcustomer:objcustomer,
          objarpu:objarpu,
          objmrc:objmrc,
          pipetrg:pipetrg,
          piperev:piperev 
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "savt", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static getObjRep(user,sel) {
        let formData = {
          user:user,
          sel:sel
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.put(url + "savt", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static insertObjProducts(user,pkg,rev,cust) {
        let formData = {
          user:user,
          pkg:pkg,
          rev:rev,
          cust:cust
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "dpqac", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static getObjProducts(user,pkg) {
        let formData = {
          user:user,
          pkg:pkg
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.put(url + "dpqac", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }
      
      static getAnnualData(user) {
        let formData = {
          user
        }
        axios.defaults.headers.put["Content-Type"] = "application/json";
    
        return  axios.put(url + "cravt", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static getSales(qtr) {
        let formData = {
          qtr:qtr
        }
        axios.defaults.headers.put["Content-Type"] = "application/json";
        return  axios.put(url + "vende/xlrws", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      
      static getSaleData(key) {
        let formData = {
          user:key
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
    
        return  axios.post(url + "vende/xlrws", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static LostCustomerQuarter(user,qtr) {
        let formData = {
          user:user,
          fil:qtr
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "lst/dmrws", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static getScheduledWorkOrders(date) 
      {
        let formData = {
          date:date
        }
        return new Promise(async (resolve, reject) => {
          try {
            const res = await axios.post(url+"tct/ctevnt",formData,
            { headers: { "Content-Type": "application/json"}});
            const data = res.data;
            resolve(data);
          } catch (e) {
            reject(e);
          }
        });
      }

      static getCasesSF(user) {
        let formData = {
          user:user
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "scf/xrw", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static getDataDay(day) {
        let formData = {
          days:day
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "xsl/dts", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getDataDayTotals(day) {
        let formData = {
          days:day
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "dxs/lmd", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getPipelineOp(user) {
        let formData = {
          user:user
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "slp", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getCorporateCustomers(user,day) {
        let formData = {
          user:user,
          day:day
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "cxpr", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getPipeline(user,day) {
        let formData = {
          user:user,
          day:day
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "asld", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getTotalSales(user,day) {
        let formData = {
          user:user,
          day:day
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "txpr", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getPipelineUser(user,f1,f2) {
        let formData = {
          user:user,
          f1:f1,
          f2:f2
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "ppsoy", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getQuotaQuarters(user,qtr,owner) {
        let formData = {
          user:user,
          qtr:qtr,
          owner:owner
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "aarrnb", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }
     

           // Inicio Team Scorecard
     static getTeamWinsvsTeamQuota(user,aqrt) {
      let formData = {
        user:user,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "twvttq", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    static getRenewalWinsvsTeamQuota(user,aqrt) {
      let formData = {
        user:user,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "rwtqtq", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    static getESGWinsvsTarget(user,aqrt) {
      let formData = {
        user:user,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "ewttq", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    static getTotalPipelineRevenue(user,aqrt) {
      let formData = {
        user:user,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "tprtq", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    static getTeamRenewalPipelinevsTarget(user,aqrt) {
      let formData = {
        user:user,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "trpttq", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    static getESGPipelinevsTarget(user,aqrt) {
      let formData = {
        user:user,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "epttq", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    // Fin Team Scorecard
     
    //Inicio Rep Activity

    static getQualifiedMeetings(user) {
      let formData = {
        user:user
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "qmra", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    static getProposalsPending(user) {
      let formData = {
        user:user
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "ppra", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    static getMRCOppsinPipeline(user) {
      let formData = {
        user:user
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "mopra", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }
    static getNRCOppsinPipeline(user) {
      let formData = {
        user:user
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "nopra", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    //Fin Rep Activity

      static getQuota(user) {
        let formData = {
          user:user
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "algpr", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getSalesPipeline(user) {
        let formData = {
          user:user
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "pdxs", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getTop5DealsClosedMRC(user,qtr) {
        let formData = {
          user:user,
          aqtr:qtr
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "tdcmts", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getTop5DealsClosedNRC(user,qtr) {
        let formData = {
          user:user,
          aqtr:qtr
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "tdcnts", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getTop5DealsPipelineMRC(user,qtr) {
        let formData = {
          user:user,
          aqtr:qtr
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "tdpmts", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getTop5DealsPipelineNRC(user,qtr) {
        let formData = {
          user:user,
          aqtr:qtr
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "tdpnts", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getTenOportunities(user,qtr) {
        let formData = {
          user:user,
          aqtr:qtr
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "optend", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }
      static getTenRenewal(user,qtr) {
        let formData = {
          user:user,
          aqtr:qtr
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "reptend", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }
      static getTenWins(user,qtr) {
        let formData = {
          user:user,
          aqtr:qtr
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "optewd", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }
      static getGoalRocks(user) {
        let formData = {
          user:user
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "glrxks", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getPackagesData(user,fil,year) {
        let formData = {
          user:user,
          fil:fil,
          year:year
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "grtqs", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }

      static getTargetDetails(user,fil,year) {
        let formData = {
          user:user,
          fil:fil,
          year:year
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "ldojsg", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }


    //Home
    static TotalRevenueTarget(user,type,aqrt){
      let formData = {
        user:user,
        type:type,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "trtqs", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }
    static TotalCustomersTarget(user,aqrt){
      let formData = {
        user:user,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "tctqs", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    static ARPUCustomersTarget(user,aqrt){
      let formData = {
        user:user,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "actqs", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    static ESGRevenueTarget(user,aqrt){
      let formData = {
        user:user,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "ertqs", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    static MRCRunRateTarget(user,aqrt){
      let formData = {
        user:user,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "mrrtqs", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    static TotalMRCvsNRC(user,type,aqrt){
      let formData = {
        user:user,
        type:type,
        aqrt:aqrt
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "tmnqs", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    //inicio Forecasting
    static ForecastingRevenue(user,pkg){
      let formData = {
        user:user,
        pkg:pkg
      }
      axios.defaults.headers.post["Content-Type"] = "application/json";
      return  axios.post(url + "prdo", formData,
        {   headers: {
          "Content-type": "application/json; charset=UTF-8"
       }}
      );
    }

    //fin Forecasting

      //inicio Gifts HOB

      static insertGift(user,seller,details,amount){
        let formData = {
          user:user,
          seller:seller,
          details:details,
          amount:amount
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "gfsran", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }}
        );
      }
      //Fin Gifts HOB

          //inicio ChatBot
          static interactBot(user,msg){
            let formData = {
              user:user,
              msg:msg
            }
            axios.defaults.headers.post["Content-Type"] = "application/json";
            return  axios.post(url + "caih", formData,
              {   headers: {
                "Content-type": "application/json; charset=UTF-8"
             }}
            );

          }

          static staticBot(user){
            let formData = {
              user:user
            }
            axios.defaults.headers.post["Content-Type"] = "application/json";
            return  axios.post(url + "caiq", formData,
              {   headers: {
                "Content-type": "application/json; charset=UTF-8"
             }}
            );

          }
     

    
      
}
export default HOBService;
<template>
  <div>
    <div class="text-center mr-1 ml-2">
      <b-avatar
        size="72px"
        :src="avatarIMG"
      />
      <b-card-text class="mt-1 h4 color-inherit text-reset">
        {{user.username}}
      </b-card-text>
      <b-badge right class="text-success font-weight-bolder badgeCrowns badgeCrownText mr-4 ml-4" v-if="exp.crowns>0">
       <span><span ><b-img :src="require('@/assets/images/illustration/crown.svg')" fluid style="width: 18px;margin-top:-4px;"/>
           {{numberWithCommas(exp.crowns)}}</span><span class="border-right">&nbsp</span><span style="margin-left:7px;" v-if="exp.goblets>0"><b-img :src="require('@/assets/images/illustration/goblet.svg')" fluid style="width: 18px;margin-top:-4px;"/> {{numberWithCommas(exp.goblets)}}
        </span></span>
         </b-badge> 
    </div>
    <div class="mt-2 border-bottom"></div>

    <b-list-group class="ml-2 mt-1 rounded-0 border-0">
      <b-list-group-item
        v-for="item in sidebarItems"
        :key="item.title"
        class="bg-transparent border-0"
        button
        @click="sendTo(item.action)"
      >
        <feather-icon
          class="align-text-bottom"
          :icon="item.icon"
        />
        <span
          class="ml-1"
          v-text="item.title"
        />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BAvatar, BListGroup, BListGroupItem, BCardText,BBadge,BImg
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,BBadge,BImg
  },
  props:["exp","avatarIMG","user"],
  data() {
    return {
      sidebarItems: [
        { title: 'Profile', icon: 'UserIcon' ,action:'profile'},
        { title: 'Faqs', icon: 'MessageCircleIcon', action:'faq'},
      ],
    }
  },
  methods:{
    sendTo(send){
        this.$router.push({ name: send}).catch(()=>{})   
   
      
     

    },
       numberWithCommas:function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
     
  }
}
</script>
<style>
.badgeCrownText{
  font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px; 
text-align: right;
letter-spacing: 0.015em;
}
.badgeCrowns{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px;
gap: 9px;
left: 78.84%;
right: 12.73%;
top: 20.31%;
bottom: 17.19%;
background: #223433;
border-radius: 8px;

}</style>
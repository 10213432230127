<template>
  <layout-vertical>
    <template>

    
      <div class="vertical-layout h-100 md:pb-[37px] pb-24">
        
    <router-view />
      </div>
    </template>

     
    <template #navbar="{ toggleVerticalMenuActive }">
      <auto-logout v-if="isNotRequired"/>
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import Navbar from '../components/Navbar.vue'
import AutoLogout from "@/components/AutoLogout.vue"
export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    AutoLogout
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  computed:{
    isNotRequired:function(){
      return this.$router.name=='login';
    }
  }
}
</script> 
<template>
  <b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right :key="cambio">
    <template #button-content>
      <feather-icon :badge="notifications.length" badge-classes="bg-danger" class="text-body bellNotifications"
        icon="BellIcon" size="22" />
    </template>

    <!-- Header -->
    <ul>
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            {{ $t("curlang") == 'en' ? 'Notifications' : 'Notificaciones' }}
          </h4>
          <b-badge pill variant="light-primary">
            {{ notifications.length ? $t("curlang") == 'en' ? notifications.length + ' New' : notifications.length + ' Nuevas' : "" }}
          </b-badge>
        </div>
      </li>
    </ul>

    <!-- Notifications -->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area"
      v-if="notifications" tagname="li">
      <!-- Account Notification -->
      <b-link v-for="(notification, index) in notifications" :key="index">
        <p v-show="false"></p>
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ $t("curlang") == 'en' ? notification.title : notification.titlees }}
            </span>
          </p>
          <small class="notification-text">{{ $t("curlang") == 'en' ? notification.message : notification.messagees }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <ul>
    <li class="dropdown-menu-footer"><b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block
        @click="vista()">{{ $t("curlang") == 'en' ? 'Read notifications' : 'Marcar como leidas' }}</b-button>
    </li>
  </ul>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import NotificationService from "@/services/NotificationService.js";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data: function () {
    return {
      notifications: [],
      cambio: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }
    }
  },
  methods: {
    vista: async function () {
      const read = (await NotificationService.ReadAllNotifications(JSON.parse(localStorage.getItem("auth")).discordId)).data
      this.$router.go(0)
    }
  },
  async mounted() {
    const notif = (await NotificationService.getUserNotifications(JSON.parse(localStorage.getItem("auth")).discordId)).data;
    let d = []
    notif.forEach(element => {
      if (element.readnot == false) {
        d.push(element)
      }
    });
    this.notifications = d;
    this.$root.$on("updateNotications", (notif) => {
      this.cambio = 1;
      let d = []

      this.notifications.forEach(element => {
        if (element.id !== notif.id) {
          d.push(element)
        }
      });
      this.notifications = [];
      this.notifications = d;
      this.cambio = 0;
      this.$forceUpdate();
    });
  },
}
</script>
<style>
.bellNotifications {
  display: flex;
  /* top: 90%; */
  bottom: 15%;
  left: 10%;
  right: 10%;
  /*margin-top: 55%;*/
}
</style>
